.dashboard-event-col {
    border-radius: .75rem;
    border: 1px solid #d9d9d9;
    padding: 1rem;
}

.highlighted {
    color: red;
}

.event-graph-col {
    height: 16vh;
    width: 100%;
    display: flex;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    padding-top: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.event-total-graph-col {
    height: 10vh;
    width: 100%;
}

.event-total-graph-col .c3-axis-y .tick {
    display: none;
}

.event-total-graph-col .c3-axis-y {
    display: none;
}

.reverse-leftmargin {
    margin-left: -1rem;
}

.event-pie-graph-user {
    width: 50%;
    height: 100%;
}

.c3-chart-arc text {
    fill: #2d2d2d;
}

.c3-chart-arc text {
    fill: #2d2d2d;
}

.event-pie-graph-world {
    width: 50%;
    height: 100%;
}

.event-pie-graph-world .c3-axis-x .tick {
    display: none;
}

.event-pie-graph-user .c3-axis-x .tick {
    display: none;
}

.event-total-graph-col .c3-axis-x .tick {
    display: none;
}

.dashboard-total-col .c3-axis-x .tick {
    display: none;
}

.dashboard-header{
    height: 4vh;
}
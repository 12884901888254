.btn-userUpdate:hover {
    background-color: #d4d4d4;
    border: 1px solid #adadad !important;
}

.btn-userUpdate:active {
    position: relative;
    top: 1px;
}

.btn-userOK {
    background: #2b542c;
    border-radius: 5px !important;
    color: whitesmoke;
}
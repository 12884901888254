.differing-age-units-warning {
    background-color: #ffe9e9;
    border-radius: 5px;
    border: 5px solid #ffd3d3;
    margin-top: 1em;
    padding: 0.4em;
    font-size: 14px;
}

.dismiss-warning {
    position: absolute;
    top: 0;
    right: -18px;
    border-radius: 50px;
    border-width: 3px;
    border-style: solid;
    opacity: 1;
    padding: 3px;
    width: 36px;
}

.dismiss-warning:hover {
    border-style: solid;
    border-color: #883737;
    cursor: pointer;
}

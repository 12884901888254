.signupContainer{
    min-height: 92vh;
    overflow: hidden;
    display: block;
    position: relative;
}

.btn-signup:hover {
    background-color: #6bd3ff;
    border: 1px solid #37b5ff !important;
}

.btn-signup:active {
    position: relative;
    top: 1px;
}

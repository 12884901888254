.replenishment-container{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #e8e8e8;
    min-height: 100vh;
    min-width: 100vw;
}

h2{
    font-weight: bold;
}

h1{
    font-weight: bold;
}

.replenishment-input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding-left: 0.5rem;
    padding-top: 0.4rem;
}

.replenishment-input:focus{
    outline: none;
    box-shadow: 0 0 0 2px #52ceff;
}

.replenishment-labels{
    padding-bottom: 2px;
    padding-top: 4px;
    padding-left: 2px;
}



.evaluation-header {
    background-color: #F5F5F5;
}

.procedure-header {
    background-color: #E6E4E2;
}

.weighted-sum {
    color: #994F00;
}

.total-count {
    color: #006CD1;
}

.observed {
    color: #961e8a;
}

.participated {
    color: #006CD1;
}

.self {
    color: #1e9296;
}

.inline-container {
    display: flex;
    align-items: center;
    justify-content: center;
}


.center-wrapper {
    display: flex;
    gap: 15px;
}

.center-wrapper > div {
    flex: 0 0 auto;
}

.sticky-header th:first-child {
    position: sticky;
    left: 0;
    background-color: #E6E4E2;
    z-index: 1;
}

.sticky-column {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #FFFFFF;
}


tbody tr:nth-child(even) .sticky-column {
    background-color: #f4f4f4;
}

.select-in-foreground {
    z-index: 2;
}



.search-user-drugbook {
    border-radius: 5px;
    border-color: #b6b6b6;
    border-style: solid;
    border-width: 2px;
    width: 100%;
    margin-bottom: 1.5em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    padding-left: 2.1em;
    background-image:url("lupp.png");
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.btn-tage-search-reset {
    border: 1px solid lightgrey;
    font-size: 14px;
    padding: 0.2em 0.5em;
    background-color: white;
    border-radius: 100%;
}

.input-wrapper {
    position: relative;
    display: inline-block;
}

.input-wrapper input {
    padding-right: 40px;
}

.input-wrapper button {
    position: absolute;
    top: 4px;
    right: 5px;
    cursor: pointer;
}
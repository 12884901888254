.react-datepicker__day--selected{
    background-color: #52ceff;
}

.dateFieldWrapper {
    position: relative;
    display: inline-block;
}

.dateFieldWrapper .dateFieldError {
    visibility: visible;
    width: 120px;
    background-color: #b7000e;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;

    opacity: 1;
    transition: opacity 0.3s;
}

.dateFieldWrapper .dateFieldError::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.statistics-number-field-warning{
    background-color: lightcoral;
    border-radius: 5px;
}

.btn-tage-small-round{
    padding: 1px 10px;
    border-radius: 50px;
}

.middle-explanation-box{
    width: 48px; /* why on gods green earth are < and ≤ different widths */
}
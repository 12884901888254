.countersignbox {
    white-space: pre-line;
    border-style: inset;
    border-width: 2px;
    border-radius: 5px;
    border-color: #cacaca;
}

.csOkButton {
    background-color: #3c763d;
    color: whitesmoke;
}

.csNoButton {
    background-color: #ac2925;
    color: whitesmoke;
}

.csRefreshBtn {
    background-color: #aaaaaa;
    border-radius: 25px;
}
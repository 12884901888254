.alignment-pad {
    padding-top: 75px;
}

#binderSelect {
    width: 100%;
}

#drugSelect {
    width: 100%;
}

.book-alignment {
    padding-top: 31px;
}

.admin-col {
    border-width: 0;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    padding-bottom: 1em;
}

.outline-journal {
    border: solid 1px #d9d9d9;
    border-radius: 5px;
}

.inside-journal-outline {
    margin-left: 5px;
    margin-right: 5px;
    padding-bottom: 5px;
}

.btn-invis-add:hover {
    background-color: lightgrey;
}

.journal-pad {
    padding-bottom: 1px;
    padding-top: 1px;
    margin: 0;
}

.btn-tage-default-red{
    color: whitesmoke;
    background-color: firebrick;
}
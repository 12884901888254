.intubationFlag {
    color: #67C886;
    font-weight: bold;
}

.cardiacArrestFlag {
    color: #EA462F;
    font-weight: bold;
}

.roscFlag {
    color: #C13825;
    font-weight: bold;
}

.thoracostomyFlag {
    color: #0E70C0;
    font-weight: bold;
}

.cvkFlag {
    color: #F9C028;
    font-weight: bold;
}


.underFiveFlag {
    color: #000000;
    font-weight: bold;
}


.underFifteenFlag {
    color: #000000;
    font-weight: bold;
}


.penetratingTraumaFlag {
    color: #72449F;
    font-weight: bold;
}

.deviationFlag {
    color: #B13F0E;
    font-weight: bold;
}
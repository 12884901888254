.optionsContainer {

}

.errorFont {
    color: #FF0000;

}

.snackbar {
    min-width: 250px;
    margin-left: -125px;
    background-color: #087708;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    z-index: 1;
    left: 50%;
    bottom: 3rem;
    position: fixed;

    -webkit-animation: fadeinout 3s ease-in-out 1 forwards;
    animation: fadeinout 3s ease-in-out 1 forwards;
}

.snackbar-button{
    position: absolute;
    top:-10px;
    right: -10px;
    border-radius: 50px;
    border-width: 3px;
    border-style: solid;
    border-color: #7bea67;
    background-color: #7bea67;
    opacity: 1;
    padding: 3px;
    width: 36px;
}

.snackbar-button:hover{
    cursor: pointer;
}

@keyframes fadeinout {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.error-messages-register{
    color: #d9534f;
}

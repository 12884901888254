.dropdown2:hover .dropdown-content2{
    max-height: 80%;
    overflow-y: scroll;
    overflow-x:hidden;
    display: block !important;
    z-index: 2;
}

.dropdown2:hover{
    border-radius: 5px 5px 5px 0;
    color: #080808;
    background-color: #8c8c8c;
}

.dropdown-content2 {
    display: none;
    position: fixed;
    overflow-x:hidden;
    max-width: 330px;
    z-index: 3;
}

.dropdown2 {
    border-radius: 5px;
    margin-top: 6px;
    float: left;
    overflow: hidden !important;
}

.dropDownButton2 {
    min-width: 330px;
    border: 0 !important;
    border-radius: 0;
    background-color: #e4e4e4;
}

.dropDownButton2:hover {
    background-color: #8c8c8c;
}

.dropDownButton2:focus {
    outline: 0;
}


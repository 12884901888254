.spinner {
    position: relative;
}

.spinner::before {
    border: solid 3px #eee;
    border-bottom-color: #525252;
    border-radius: 70%;
    content: "";
    height: 20px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 20px;

    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

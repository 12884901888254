.loginPageLink:hover {
    cursor: pointer;
    color: black !important;
}

.loginPageLink {
    text-decoration: underline !important;
    color: black !important;
}

.navbar-forgotten-link {
    color: whitesmoke;
    font-size: 15px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.navbar-forgotten-link-mobile {
    color: #4c4c4c;
    font-size: 15px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.floating-eye {
    position: absolute;
    z-index: 3;
    background-color: inherit;
    top: 1px;
    right: 11px;
}

.toast {
    min-width: 250px;
    margin-left: -125px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    z-index: 1;
    left: 50%;
    bottom: 3rem;
    position: fixed;

    transition-timing-function: ease-out;
    transition: 0.25s;
    transform: translateY(0);
}

.toast-bg-green{
    background-color: #087708;
}

.toast-bg-red{
    background-color: #800000;
}

.hide-alert{
    transition-timing-function: ease-in;
    transition: 0.2s;
    transform: translateY(250%);
}

.toast-button{
    position: absolute;
    top:-10px;
    right: -10px;
    border-radius: 50px;
    border-width: 3px;
    border-style: solid;
    opacity: 1;
    padding: 3px;
    width: 36px;
}

.toast-btn-green{
    border-color: #7bea67;
    background-color: #7bea67;
}

.toast-btn-red{
    border-color: #c04c4c;
    background-color: #c04c4c;
}

.toast-button:hover{
    cursor: pointer;
}

.location-card {
    width: 18rem;
    margin-bottom: 10px !important;
}

.btn-drugbook:hover {
    color: whitesmoke !important;
}

.userpage-box {
    max-width: 600px;
}

.userpage-drug-box {
    margin-bottom: 2em !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    padding-bottom: 1em;
    margin-right: 2em;

    background-color: #fafafa;
    border-width: 1px;
    border-style: solid;
    border-color: #e0e0e0;
    border-radius: 8px;
}

.btn-tage-somethingelse {
    background-color: white !important;
    border: 1px #3e3e3e solid !important;
    border-radius: 5px;
    min-width:78px
}

.btn-tage-recently-checked {
    background-color: #b9fdb9 !important;
    border: 1px #3e3e3e solid !important;
    border-radius: 5px;
    min-width:78px
}

.btn-tage-somethingelse:hover {
    border: 1px #5e5e5e;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}

.btn-tage-drug-book-move {
    background-color: white !important;
    border: 1px #3e3e3e solid !important;
    border-radius: 5px;
    min-width:78px
}

.btn-tage-drug-book-move:hover {
    border: 1px #5e5e5e;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}

.btn-tage-somethingelse:active {
    position: relative;
    top: 1px;
}

.kb-position-relative {
    position: relative;
}

.userpage-box .col .header-row:first-child {
    padding-top: 1em;
}

.sub-drug-header {
    font-size: 14px;
}

.sent-email-warning{
    padding-left: 2em;
    color: #bf0000;
}

.wave h6 {
    display: inline-block;
    animation: flip 3s infinite;
    animation-delay: calc(.1s * var(--i-knarkbok))
}

@keyframes flip {
    0%{
        transform: rotateY(360deg);
    }
    85% {
        transform: rotateY(360deg);
    }
}


.no-line-break {
    white-space: pre;
}

.tutor-column {
}

.driving-time-column {
}

.scroll-sideways {
    overflow-x: auto;
}

.emptyDiv {
}

.strong {
    font-weight: 700;
}

.tutor {
    color: #994F00;
}


.student {
    color: #006CD1;
}



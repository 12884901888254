.dashboard-container {
    min-height: 100vh;
    background-color: white;
    -moz-box-shadow: 0 0 2rem 2rem #ffffff;
    -webkit-box-shadow: 0 0 2rem 2rem #ffffff;
    box-shadow: 0 0 2rem 2rem #ffffff;

    font-feature-settings: normal;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-variation-settings: normal;
}

.dashboard-background-container {
    width: 100%;
    height: 100%;

    --size: 25px;

    --c: #0000 75%, #fafafa 0;
    --g1: conic-gradient(at 78% 3%, var(--c));
    --g2: conic-gradient(at 3% 78%, var(--c));
    background: var(--g1),
    var(--g1) var(--size) var(--size),
    var(--g1) calc(2 * var(--size)) calc(2 * var(--size)),
    var(--g1) calc(3 * var(--size)) calc(3 * var(--size)),
    var(--g2) 0 calc(3 * var(--size)),
    var(--g2) var(--size) 0,
    var(--g2) calc(2 * var(--size)) var(--size),
    var(--g2) calc(3 * var(--size)) calc(2 * var(--size)) #ffffff;
    background-size: calc(4 * var(--size)) calc(4 * var(--size));
}
.sublist {
    border-radius: 5px;
}

.dropdown {
    border-radius: 5px;
    margin-top: 6px;
    float: left;
    overflow: hidden !important;
}

.dropdown:hover {
    border-radius: 5px 5px 5px 0;
    color: #080808;
    background-color: #8c8c8c;
}

.dropdown-content {
    display: none;
    position: fixed;
    max-width: 330px;
    z-index: 3;
}

.navbarHeaderContainer {
    z-index: 2;
    position: fixed;
    top: 0;
    transition: top 0.2s ease-in-out;
    width: 100%;
}

/*
nav-up har 66px enligt headerns höjd
 */
.nav-up {
    top: -66px;
}

.dropdown:hover .dropdown-content {
    max-height: 80%;
    overflow-y: scroll;
    overflow-x:hidden;
    display: block !important;
    z-index: 2;
}

.dropDownButton {
    min-width: 330px;
    border: 0 !important;
    border-radius: 0;
    background-color: #e4e4e4;
    overflow-x:hidden;
}

.dropDownButton:hover {
    background-color: #8c8c8c;
}

.dropDownButton:focus {
    outline: 0;
}

.sublistScrollable {
    overflow-y: scroll;
    overflow-x:hidden;
}

.navBarMailButton {
    background-color: #323232;
    color: #949494;
    border-radius: 4px;
    border-width: 3px;
    border-color: #676767;
    height: 20px;
    margin: 14px 3px 0;
    padding: 0 1px 0;
    border-style: solid;
    overflow-y: hidden;
    line-height: 16px;
}

.navBarMailButtonInnerText {
    font-size: 30px;
    color: #676767;
    height: 18px;
    transform: translate(0px, -20px);
}

.navbar-org-select{
    background-color: #c37bf5;
    color: #0c2311;
    margin-top: 10px;
}

@media only screen and (max-width: 991px) {
    .visibleBig {
        display: none;
    }

    .registerButton {
        display: block;
    }
}

@media only screen and (min-width: 992px) {
    .visibleBig {
        display: block;
    }

    .registerButton {
        display: none;
    }
}

.navbar-org-select {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 16px;
    color: #f1f1f1;
    background-color: #525252;
    width: max(16vw, 110px);
    height: 30px;
    border: none;
    border-radius: 2px;
}

.navbar-org-select > option {
    background-color: red;
    color: orange;
}

.navbar-org-select-option{
    background-color: red;
    color: orange;
}

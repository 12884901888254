#qrCanvas{
    border:1px solid black;

    width: 592px;
    height: 592px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: pixelated;
    image-rendering: crisp-edges;
}

.inverted{
    background: black;
    color:whitesmoke;
}
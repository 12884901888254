.btnDuplication {
    background-color: #adadad;
    padding: 2px 10px 2px 10px;
}

.btnDuplication:active {
    position: relative;
    top: 1px;
}

.btnDuplication:hover {
    background-color: #d4d4d4;
    border: 1px solid #adadad !important;
}

.uau-table th{
    padding: 0.5em 7em 0.5em 0.5em;
}

.uau-table th:last-child{
    padding: 0.5em 1em 0.5em 0.5em;
}

.uau-table td {
    padding: 0.5em 7em 0.5em 0.5em;
}

.uau-table td:last-child {
    padding: 0.5em 1em 0.5em 0.5em;
}

.uau-table .btn:hover{
    position: relative;
    top:1px;
}

.ua-container .btn:active{
    position: relative;
    top:1px
}

.btn-mini{
    padding: 0 2px;
    margin:0;
    border-radius: 6px;
}

.uaselected{
    border-top: 3px solid;
    border-bottom: 3px solid;
    border-color: #c6e7ff;
    background-color: #52ceff;
}
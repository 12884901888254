.dashboard {
    width: 90%;
    margin: auto;
    max-width: 1200px;
    padding-bottom: 10px;
    font-family: Roboto, sans-serif;
}

.header {
    margin: 40px 0;
}

.parent-card, .child-card {
    transition: box-shadow 0.3s, transform 0.5s;
    background-color: #fff;
    border-radius: 15px;
    padding: 25px;
    margin: 20px 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.parent-card {
    border-left: 4px solid #cccccc;
}

.parent-card:hover, .parent-card:focus-within {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.child-card {
    border-left: 4px solid #cccccc;
    margin-left: 50px;
}

.child-card:hover, .child-card:focus {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.parent-card h2, .child-card h3 {
    margin: 0 0 10px;
    font-weight: 500;
    color: #333;
}

.labels {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: left;
}

.label {
    padding: 7px 20px;
    border-radius: 15px;
    font-size: 0.9em;
    font-weight: 500;
    color: #333;
}

.delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #ff4b4b;
    border: none;
    color: white;
    padding: 3px 15px;
    font-size: 1.3em;
    border-radius: 15px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.delete-btn:hover {
    background-color: red;
    transform: scale(1.05);
}

.parent-card, .child-card {
    position: relative;
}


#workplace-select {
    width: 40%;
    font-size: 1em;
    padding: 15px 0 0;
    border-radius: 10px;
    transition: border-color 0.3s;
}

#workplace-select:focus {
    border-color: #007bff;
    outline: none;
}

@media (max-width: 768px) {
    .dashboard {
        width: 95%;
    }

    .parent-card {
        padding: 20px;
        margin: 15px 0;
    }

    .child-card {
        margin: 15px 10px;
        padding: 15px;
    }

    .labels {
        justify-content: left;
    }

    .label {
        font-size: 1em;
    }

    .parent-card h2, .child-card h3 {
        font-size: 1.2em;
    }

    #workplace-select {
        width: 90%;
        font-size: 1em;
        padding: 14px 0 0;
    }
}

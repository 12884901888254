.knarkbok-modal-fullscreen {
    position: fixed;
    background-color: whitesmoke;
    z-index: 2;
    left: 50%;
    right: 50%;
    top: 50%;
    border-radius: 15px;
    border: 3px solid #3e3e3e;
    min-width: min(90vw, 30em);
    max-width: 800px;
    width: 95%;
    height: auto;
    max-height: 95%;
    overflow: hidden;
    padding: 1em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: translate(-50%, -50%);
    display: grid;
}

.knarkbok-modal-background-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    transform: translateZ(0);
    background-color: rgba(000, 000, 000, 0.15);
}

.modal-input{
    max-width: 35vw;
}


.from-bottom-rel {
    align-self: end;
}

.possibly-faulty{
    border: 3px dashed red;
    border-radius: 5px;
    margin-left: -3px;
}

.fullscreen-header-color {
}

.medicine-list {
    max-height: 67vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.max-60{
    max-height: 62vh;
}

.witness-warning{
    border: 3px dashed red;
    border-radius: 5px;
    margin-left: -3px;
}

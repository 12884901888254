.user-border {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    border-width: 0.5px;
    border-radius: 8px;
    border-color: #0c2311;
    padding-bottom: 1em;
    width: 35vw;
}

.organisation-border {
    border-width: 0.5px;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    padding-bottom: 2em;
    margin-left: 10px;
    margin-right: 10px;
}
.edit-organisation-roles {
    position: fixed;
    background-color: white;
    z-index: 2;
    left: 50%;
    top: 50%;
    height: auto;
    border-radius: 15px;
    border: 3px solid #3e3e3e;
    width: 35vw;
    max-width: 500px;
    padding: 1em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.modal-container {
    text-align: left;
    width: 100%;
}

.border-role {
    border-radius: 8px;
    border: 2px inset #eaeaea;
    background-color: #ffffff;
    min-height: 2em;
    max-height: 25vh;
    overflow-y: scroll;
}

.border-role .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
    flex-wrap: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.edit-organisation-roles-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    transform: translateZ(0);
    background-color: rgba(0, 0, 0, 0.15);
}

.user-overview-header {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.card-h {
    font-weight: bold;
    font-size: 1.2em;
}

.user-overview-button {
    background-color: #52ceff;
    color: black !important;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

.search-input {
    width: 35vw;
    padding: 8px;
    background-color: white;
    border: 1.5px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: border 0.3s ease-in-out;
}

.search-input:focus {
    border: 1px solid black;
    outline: none;
}

.user-overview-button:hover {
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .edit-organisation-roles {
        width: 95%;
    }

    .user-border {
        width: 95%;
    }

    .search-input {
        width: 95%;
    }

}

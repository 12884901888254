.selected {
    background: #c8c8c8;
}

.organisation-card {
    background: hotpink;
}

.role-card {
    background: lightpink;
}

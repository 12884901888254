.fadeable{
    color: #080808;
}

.faded{
    color: #b6b6b6;
}
.graybar{
    background-color: #d8d8d8;
    border-radius: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
}
.btn-exp-expand{
    background-color: #8c8c8c;
    color: white;
    margin: -2px 3px 4px;
    padding: 4px 7px 3px;
}

.active-exp-button{
    background-color: #52ceff;
}

.active-exp-plus{
    background-color: #52ceff !important;
}

.btn-exp-summary{
    background-color: #8c8c8c;
    color: white;
    margin: 0px 1px 1px;
    padding: 0px 9px 0px;

}
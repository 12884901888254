.btn-newPassword {
    background-color: #52ceff;
    padding-bottom: 2px;
    padding-top: 2px;
    margin-bottom: 4px;
    max-height: 30px;
}

.btn-newPassword:hover {
    background-color: #98e7ff;
    border: 1px solid #6ba9ff !important;
}

.btn-newPassword:active {
    position: relative;
    top: 1px;
}

.btn-register-empty {
    background: #52ceff;
    color: black;
}

.btn-login-empty {
    background: #ff952a;
    color: black;
}

@media only screen and (max-width: 991px) {
    .visibleBig {
        display: none;
    }
}

@media only screen and (min-width: 992px) {
    .visibleBig {
        display: block;
    }
}

.knarkbok-modal{
    position: fixed;
    background-color: whitesmoke;
    z-index: 2;
    left: 50%;
    right: 50%;
    top: 50%;
    height: auto;
    border-radius: 15px;
    border: 3px solid #3e3e3e;
    min-width: min(90vw, 30em);
    padding: 1em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: translate(-50%, -50%);
}

.knarkbok-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    transform: translateZ(0);
    background-color: rgba(000, 000, 000, 0.15);
}

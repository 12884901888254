.btn-close-focus {
    background-color: #d7d7d7;
    padding: 1px 5px;
}

.hover-pointer:hover {
    cursor: pointer;
}

.kb-modal-scrollable {
    max-height: 75vh;
    overflow: auto;
}

.container2 {
    width: 100%;
}

.redwarning {
    background-color: #e57575 !important;
}

.drug-event-table {
    width: 100%;
    border-collapse: collapse;
}

.drug-event-table th, .drug-event-table td {
    border: none;
    padding: 8px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.drug-event-table tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.05);
}

.drug-event-table .redwarning {
    background-color: #e57575 !important;
}

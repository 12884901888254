.btn-tage-1 {
    background-color: #52ceff;
}

.btn-tage-1:hover {
    filter: brightness(110%);
}

.btn-tage-1:active {
    filter: brightness(90%);
    position: relative;
    top: 2px
}


.btn-tage-loading-1 {
    background-color: #004966;
    color:#c8efff ;
}

.btn-tage-loading-1:hover {

}

.loader {
    width: 16px;
    height: 16px;
    border: 3px solid #c8efff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.playground {
    min-width: 90vw;
    min-height: 30vh;
    overflow: hidden;
}

.unikt_klassnamn_a {
    visibility: hidden;
}

.unikt_klassnamn_b {
    visibility: hidden;
}

.synligt {
    visibility: inherit !important;
    animation: testAnim 1.5s ease-out;
}

@keyframes testAnim {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.synligt2 {
    visibility: inherit !important;
    animation: testAnim2 1.5s ease-in;
}

@keyframes testAnim2 {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.allowOverflow {
    overflow: visible !important;
}

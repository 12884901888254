.btn:focus, .btn:active {
    outline: none !important;
    box-shadow: none;
}

h5{
    margin-bottom: 0;
    margin-top: 1em;
}

label{
    margin-bottom: 0;
}

.btn-tage-default{
    background-color: #52ceff;
    color: black !important;
}

.btn-tage-default:disabled {
    background-color: #a0dfff;
    color: #666 !important;
    opacity: 0.6;
    cursor: not-allowed;
}

.btn-tage-default:hover{
    color:whitesmoke;
}

.btn-tage-default-cancel{
    background-color: rgba(121, 121, 121, 0.73);
    color: black !important;
}

.btn-tage-default-cancel:hover{
    color:whitesmoke !important;
    background-color: #292929;
}

.btn-save:active {
    position: relative;
    top: 1px
}

.ci-cell{
    padding-top: 0.75rem !important;
}


.progress {
    height: 2.5em;
    width: 100%;
    background-color: #dfdfdf;
    position: relative;
}

.progress:before {
    content: attr(data-label);
    font-size: 1.2em;
    position: absolute;
    text-align: center;
    top: 5px;
    left: 0;
    right: 0;
}

.progress .value {
    display: inline-block;
    height: 100%;
}

.progress .green {
    background-color: #33b329;
}

.progress .orange {
    background-color: #eaad37;
}

.progress .red {
    background-color: #ff2d09;
}

.table thead th {
    border-bottom: 0;
}

.table td, .table th {
    border-top: 0;
}

.sticky-cell{
    position: sticky;
    right: 0;
}
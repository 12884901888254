.someclassname {
    margin-left: 10px;
    margin-right: 10px;
}

.activeMove {
    background-color: #52ceff !important;
    color: black !important;
}

.btn-move {
    background-color: #8c8c8c;
    color: whitesmoke;
}

.innermodal-warning-text {
    color: #960003;
}

.btn-red {
    background-color: rosybrown;
}

.btn-tiny-expand {
    padding: 0px 5px;

}

.max-size-input{
    width: 100%;
}

.kb-floating-top{
    position: relative;
    top:1.8vh;
}

.kb-floating-bottom{
    position: relative;
    bottom:1.8vh;
}

.btn-tage-green{
    background-color: lightseagreen;
}

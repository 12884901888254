.btn-pressable:active {
    position: relative !important;
    top: 1px;
}

.flashing-red-warning {
    color: red;
    animation: blinking 1s;
    border-radius: 3px;
}

@keyframes blinking {
    0% {
        background-color: #d10606;
        box-shadow: 5px 5px 5px 10px #d10606;
    }
    100% {
        background-color: #ffffff;
        box-shadow: none;
    }
}

.loading-canvas {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 3;
    padding-top: 76px;
}

.loading-canvas-background {
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom:0;
    z-index: 2;
    width: 100%;
    background-color: white;
    margin-top: 76px;
    height: 62vh;
}

.loading-text{
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom:0;
    z-index: 4;
    width: 100%;
    height: 20px;
}

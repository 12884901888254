.partial-container{
    max-height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.pixelpad{
    padding-right:4px;
    padding-top: 0px;
    padding-bottom: 0;
    padding-left: 0px;
}

.float-button{
    position: relative;
}

.partial-input{
    width: 30%;
    margin-right: 0.3em;
}

.partial-input2{
    width: 60%;
    margin-right: 0.3em;
}


.label-full-width{
    width: 100%;
}
.righSideBox {
    background-color: #828282;
    min-height: 80vh;
}

.rightSideImageBox {
    min-height: 100vh;
    background-repeat: space;
    background-image: url("tile_grey_space.png");
    opacity: 0.2;
}

.leftSideBox {
    background-color: #a1a1a1;
    min-height: 90vh;
}

.leftSideTextBox {
    font-family: 'Marcellus', serif;
    font-size: 42px;
    color: #101010;
    position: absolute;
    top: 20%;
    left: 10%;
    transform: translate(-50%, -50%);
}

.leftSideTextBoxSmall {
    font-family: 'Marcellus', serif;
    font-size: 20px;
    color: #101010;
    position: absolute;
    top: 24%;
    left: 9%;
    transform: translate(-50%, -50%);
}

.verticalSpacer {
    margin-top: 30vh;
}

.image-container {
    position: relative;
    text-align: center;
    color: white;
    left: 30%;
    top: 10%;
}

.filur-image {
    opacity: 0.5;
}

.bottomInfoCards {
    background-color: #a1a1a1;
    min-height: 40vh;
}

.registerButtonLeftSide {
    position: absolute;
    top: 28%;
    left: 0%;
}

.btn-register {
    background: #52ceff;
}

.btn-register:hover {
    background: #89e0ff;
}

.btn-register:active {
    position: relative;
    top: 1px;
}

.cardWidth {
    width: 18rem;
    border: none !important;
    background-color: lightgrey;
}

.aboutFooterRow {
    min-height: 10vh;
    color: #f8f6f6;
    background-color: black;
    max-width: 100vw;
    margin-right: 0;
    margin-left: 0;
}

.shadowy {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.smallprint{
    font-family: 'Marcellus', serif;
    font-size: 12px;
}

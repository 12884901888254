.register-modal {
    position: fixed;
    z-index: 2;
    left: 50%;
    right: 50%;
    top: 50%;
    height: auto;
    border-radius: 15px;
    border: 3px solid #3e3e3e;
    min-width: 30em;
    padding-left: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
    box-shadow: 0 4px 8px 0 #7a0000, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: translate(-50%, -50%);

    -webkit-animation: fadein 400ms; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 400ms; /* Firefox < 16 */
    -o-animation: fadein 400ms; /* Opera < 12.1 */
    animation: fadein 400ms;
}

.failed-registration {
    border-color: #d9534f;
    background-color: white;
}

.register-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    transform: translateZ(0);
    background-color: rgba(000, 000, 000, 0.15);

    -webkit-animation: fadein 200ms; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 200ms; /* Firefox < 16 */
    -o-animation: fadein 200ms; /* Opera < 12.1 */
    animation: fadein 200ms;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

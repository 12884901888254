
.create-user-modal {
    position: fixed;
    background-color: whitesmoke;
    z-index: 2;
    left: 20%;
    top: 50%;
    height: auto;
    border-radius: 15px;
    border: 3px solid #3e3e3e;
    width: 90vw;
    padding-left: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: translate(-17.5%, -50%);
    display: flex;
    justify-content: space-around;
}

.create-user-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    transform: translateZ(0);
    background-color: rgba(000, 000, 000, 0.15);
}


.create-unregistered-staff-modal {
    position: fixed;
    background-color: whitesmoke;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 400px;
    max-width: 90vw;
    height: auto;

    border-radius: 15px;
    border: 3px solid #3e3e3e;

    padding: 1em;

    display: flex;
    flex-direction: column;
    align-items: center;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.button-row {
    padding-top: 1em;
    display: flex;
    justify-content: left;
    gap: 0.6em
}
.border-role {
    border-radius: 8px;
    border: 2px inset #eaeaea;
    background-color: #ffffff;
    min-height: 2em;
    max-height: 25vh;
    overflow-y: scroll;
}

.btn-g{
    background-color: #89d589;
}

.btn-g:hover{
    background-color: #b3ecb3;
}


.btn-r{
    background-color: #d37676;
}

.btn-r:hover{
    background-color: #d37676;
}

.create-user-modal .row:last-child{
    padding-bottom: 0.25rem;
}

.deselected{
    background-color: #e4e4e4;
    color: #ffffff;
}

.deselected .btn-g{
    background-color: #9c9c9c;
    color: lightgrey;
}

.missing-mandatory{
    border: 3px dashed red;
}
.expandingSpacer {
    height: 24px;
}

.fade-in {
    animation: fadein 0.3s ease-out;
}

.expandingSpacerWrapper {
}

@keyframes fadein {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

.allowOverflow {
    overflow: visible !important;
}

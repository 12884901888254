.luft {
    min-height: 50vh;
}

.succeedText {
    background-color: #67b168;
}

.errorText {
 background-color: #ce8483;
}

.user-progress-ci{
    border-color: #3e3e3e;
    border-width: 2px;
    border-radius: 5px;
    border-style: solid;
}

.progress-bar-colors{
    border-radius: 4px;
    background-color: darkgreen;
}

.progress-bar-background{
    border-radius: 4px;
background-color: lightgrey;
}

.btn-tage-default-claimed{
    background-color: #008001;
}

.button-1{

}

.button-1:active {
    position: relative;
    top: 2px
}

.button-2{

}

.button-2:active {
    position: relative;
    top: 2px
}


.button-3{

}
:root {
    --main-color: #222;
    --text-color: #9d9d9d;
    --active-color: #080808;
    --footer-color: #f4f4f4;
    --error-color: #ff6a7a;
}

.input-login {
    width: 100%;
}

.active {
    background-color: var(--active-color);
    color: #FFFFFF !important;
}

.footerText {
    background-color: var(--footer-color);
    font-size: 12px;
}

.navBarStyle {
    background-color: var(--main-color);
}

.wholeSite{
    min-height: 100vh;
}

.image-mid-container{
    height: calc(74vh - 70px);
}

.loginButtonRow {
    padding-top: 10px;
}

.btn-login {
    background-color: #52ceff;
    color: black;
    border-radius: 5px;
    font-size: 16px;
}

.btn-login:hover {
    background-color: #FFFFFF;
}

.btn-revealPassword {
    background-color: var(--text-color);
}

.btn-revealPassword:hover {
    background-color: #FFFFFF;
}

.btn-signup {
    background-color: #52ceff;
    color: black;
    border-radius: 5px;
    font-size: 16px;
}

.grayed {
    background-color: var(--footer-color);
}

.loginRow {
    padding-bottom: 4px;
}

.loginBox {
    background-color: var(--main-color);
    color: var(--text-color);
    display: inline-block;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 10px;
    border-style: solid;
    border-color: var(--text-color);
}

.background-image {
    max-width: 100%;
    height: auto;
    opacity: 0.2;
}

.navbar-toggler {
    color: var(--text-color);
}

.navbar-toggler :focus {
    outline: 0 !important;
}

.btn-custom-tage-primary {
    color: var(--text-color);
}

.btn-custom-tage-primary:hover {
    color: #FFFFFF;
}

.btn-custom-tage-primary:active {
    background-color: #080808;
}

a:link, a:visited, a:hover, a:active {
    text-decoration: none;
}

.btn:focus, .btn:active {
    outline: none !important;
    box-shadow: none;
}

.navbar-brand {
    color: var(--text-color);
}
/*
maincontainer har 66px padding för att vara under fixerad navbar som har höjden 66px
 */
.loginContainer {
    padding-top: 66px;
    overflow: hidden;
    display: block;
    position: relative;
}

.mainContainer {
    padding-top: 66px;
    min-height: calc(100vh - 18px);
    overflow: hidden;
    display: block;
    position: relative;
}

.loadingSnurra {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 60vh;
    margin-left: 50vw;
    margin-right: 50vw;
    width: 100px; /* Need a specific value to work */
}

.blackBall {
    border: solid 3px #000000;
    border-radius: 100%;
    background-color: #4b4b4b;
    content: "";
    height: 12px;
    width: 12px;
    left: 50%;
    position: absolute;
}

.bigSpinner {
    position: relative;
}

.bigSpinner::before {
    border: solid 5px #eee;
    border-bottom-color: #525252;
    border-radius: 70%;
    content: "";
    height: 50px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 50px;

    animation: spin 2s linear infinite;
}

.noPoints {
    list-style-type: none;
}

input {
    margin-right: 10px;
}

.hidden {
    display: none;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loginMobileBox {
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
}

.loginButton {
    display: inline-block;
    padding: 6px 16px;
    border-radius: 6px;
    border-width: 2px;
    border-color: #197d9b;
    color: #FFFFFF;
    font-size: 1rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.loginButton:hover {
    color: #222;
    transition-property: border-color;
    transition-duration: 0.4s;
    transition-delay: 0.1s;
    border-color: #FFFFFF;
}

.loginButton:hover:before {
    width: 100%;
}

.loginButton::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #FFFFFF;
    transition: all 0.3s;
    border-radius: 6px;
    z-index: -1;
}

.loginButton::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #52ceff;
    z-index: -2;
}

.login-navbar{
    font-size: 19px;
    background-color: #52ceff;
}

.login-navbar:hover{
    font-size: 19px;
    background-color: #52ceff;
    color: whitesmoke;
}


.superpad{
    padding-top: 580px;
}

.containsDeficiency{
    background-color: rgba(248, 56, 56, 0.64);
}

.containsExcess{
    background-color: rgba(255, 248, 86, 0.64);
}

.containsDeficiencyAndExcess{
    background-color: rgba(248, 56, 56, 0.64);
}


.kb-overview-header, .kb-overview-row {
    gap: 10px;
    flex-wrap: nowrap;
}

.knarkbok-modal2{
    position: fixed;
    background-color: whitesmoke;
    z-index: 3;
    left: 20%;
    right: -10%;
    top: 50%;
    height: auto;
    border-radius: 15px;
    border: 3px solid #3e3e3e;
    width: 90vw;
    padding-left: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: translate(-17.5%, -50%);
    display: flex;
    justify-content: space-around;
}

.knarkbok-modal-background2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transform: translateZ(0);
    background-color: rgba(000, 000, 000, 0.15);
}

.kb-overview-grid{
}

.kb-grayed{
    background-color: rgba(000, 000, 000, 0.10);
}

.kb-overview-header{
    border-radius: 10px 10px 0 0;
}

.b-wrapper{
    overflow-x: auto !important;
    max-height: 75vh;
}

.overflow-b {
    padding-bottom: 20px;
    height: inherit;
    width: inherit !important;
    min-width: 100% !important;
}

.ro2{
    flex-wrap: nowrap;
}

.kbo-1{
    width: 100vw;
    background-color: red;
    overflow-x: scroll;
}

.kb-overview-table {
    border-collapse: collapse;
}

.kb-overview-table th, .kb-overview-table td {
    border: none;
    padding: 8px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.kb-overview-table tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.05);
}

.kb-overview-table tr:hover:not(:first-child) {
    background-color: rgba(0, 0, 0, 0.2);
}

.kb-overview-table td:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}

.kb-overview-table .containsDeficiency {
    background-color: rgba(248, 56, 56, 0.64);
}

.kb-overview-table .containsExcess {
    background-color: rgba(255, 248, 86, 0.64);
}

.kb-overview-table .containsDeficiencyAndExcess {
    background-color: rgba(248, 56, 56, 0.64);
}

.kb-overview-table .sticky-column {
    background-color: rgb(255, 255, 255);
}


.pharmacy-container {

}

.pharmacy-container-row {
    border: 2px solid #eeeeee;
    border-radius: 15px;
}

.pharmacy-container-bottom-row{

}
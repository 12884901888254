
.temporary-dummy-modal {
    position: fixed;
    background-color: whitesmoke;
    z-index: 2;
    left: 50%;
    right: 50%;
    top: 50%;
    height: auto;
    border-radius: 15px;
    border: 3px solid #3e3e3e;
    max-height: 100%;
    min-width: 40em;
    padding-left: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: translate(-50%, -50%);
    overflow-y: scroll;
    overflow-x: scroll;
}

@media (max-width: 480px) {
    .temporary-dummy-modal {
        min-width: 20em;
    }
}

.close-button {
    position: absolute;
    top: 0;
    right: 0;
    background: #f6f6f7;
    border: 0;
    cursor: pointer;
}

.temporary-dummy-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    transform: translateZ(0);
    background-color: rgba(000, 000, 000, 0.15);
}

.agg-mission-report {
    min-width: 780px;
}

.col-nowrap {
    white-space: nowrap;
}
